var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:_vm.$style.card},[_c('div',{staticClass:"d-flex mb-4"},[_c('ClearLocalStorage',{attrs:{"default_cols":_vm.cols_default,"storage_name":_vm.cols_storage_name,"cols_list":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols_default=$event}}})],1),_vm._l((_vm.updated_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{attrs:{"headers":_vm.updated_cols,"items":_vm.products,"no-data-text":"Продукты, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.$style.textWrap},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])]}},{key:"item.price_rub",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.price_rub))+" ")])]}},{key:"item.client_price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.client_price))+" ")])]}},{key:"item.desired_price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.desired_price))+" ")])]}},{key:"item.agreed_price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.agreed_price))+" ")])]}},{key:"item.date_agreed_price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("date")(item.date_agreed_price))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('tr',[_c('v-btn',{attrs:{"text":"","outlined":"","disabled":!item.history.length,"title":"Удалить"},on:{"click":function($event){return _vm.show_history_price_modal(item)}}},[_vm._v(" История изменения ")])],1)])]}}],null,true)})],2),_c('SimpleDialog',{attrs:{"max-width":"1100"},on:{"close":_vm.close_history_price_modal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" История изменения ")]},proxy:true},{key:"text",fn:function(){return [_c('Table',{attrs:{"headers":_vm.history_cols,"items":_vm.product_history,"hide-default-footer":"","not_resizeble":true},scopedSlots:_vm._u([{key:"item.agreed_price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.agreed_price))+" ")])]}},{key:"item.date_agreed_price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("date")(item.date_agreed_price))+" ")])]}}],null,true)})]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"d-none"})]},proxy:true}]),model:{value:(_vm.show_history_modal),callback:function ($$v) {_vm.show_history_modal=$$v},expression:"show_history_modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }